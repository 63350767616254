<template>
  <section class="page-articles">
    <div
      v-if="initializing"
      class="flex flex-col items-center justify-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <van-search
        v-model="keyword"
        show-action
        placeholder="请输入搜索关键字"
        :disabled="loading"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">
            搜索
          </div>
        </template>
      </van-search>
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="
            articles.to === null || articles.to == articles.total
          "
          :finished-text="loading ? '加载中...' : (!articles.total ? '' : '没有更多了')"
          error-text="加载失败，请下拉页面重新加载"
          :immediate-check="false"
          @load="fetch"
        >
          <van-cell
            v-for="article of articles.data"
            :key="`article-${article._id}`"
            :is-link="true"
            class="article-item"
            @click="goLink(article)"
          >
            <template #title>
              <van-tag
                v-if="article.onTop"
                round
                type="danger"
                class="ml-1"
              >
                置顶
              </van-tag>
              {{ article.title }}
            </template>
            <template #label>
              <p class="mt-2">
                发布时间：{{
                  $dayjs(article.published_at).format('YYYY-MM-DD HH:mm:ss')
                }}
              </p>
              <p class="flex flex-row items-center justify-start mt-2">
                类别：<van-tag
                  plain
                  type="primary"
                  class="px-2 py-1"
                >
                  {{ article.category }}
                </van-tag>
                <van-tag
                  v-if="article.authorizationRequired"
                  plain
                  type="warning"
                  class="px-2 py-1 ml-2"
                >
                  仅限业主访问
                </van-tag>
              </p>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
      <van-empty
        v-if="!loading && !articles.total"
        description="暂无相关内容"
      />
    </template>
  </section>
</template>

<script>
import share from '@/mixins/share'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'PublicFire',
  mixins: [share],
  data() {
    return {
      collection: null,
      initializing: true,
      keyword: null,
      mode: null,
      from: null,
      loading: false,
      refreshing: false,
      articles: {
        current_page: null,
        to: 0,
        total: 0,
        data: []
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['db', '_']),
    ...mapGetters('Common/Credential', ['userInfo'])
  },
  async mounted() {
    this.collection = this.$route.params.collection
    await Promise.all([this.fetch(), this.prepareWx()])
    let options = {
      title: '消防专题',
      desc: '北京阳光丽景小区消防专题',
    }
    this.updateShareData(options)
  },
  methods: {
    onRefresh() {
      this.refreshing = true
      this.fetch()
    },
    async fetch() {
      if (this.refreshing) {
        // eslint-disable-next-line no-console
        console.warn('这是在刷新页面')
        this.articles = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
        this.refreshing = false
      }
      let page = (this.articles.current_page ?? 0) + 1

      this.loading = true
      let query = {
        status: this._.eq('已发布')
      }
      if (this.keyword) {
        // eslint-disable-next-line no-console
        console.warn('搜索关键字', this.keyword)
        query = this._.and([
          {
            status: this._.eq('已发布')
          },
          this._.or([
            {
              title: this.db.RegExp({
                regexp: '.*' + this.keyword,
                options: 'i'
              })
            },
            {
              content: this.db.RegExp({
                regexp: '.*' + this.keyword,
                options: 'i'
              })
            }
          ])
        ])
      }
      try {
        let countResult = await this.db
          .collection(this.collection)
          .where(query)
          .count()
        const total = countResult?.total ?? 0
        // eslint-disable-next-line no-console
        console.warn('总共有数据', total, this.articles, page)

        let result = await this.db
          .collection(this.collection)
          .where(query)
          .orderBy('published_at', 'desc')
          .skip((page - 1) * 10)
          .limit(10)
          .get()
        // eslint-disable-next-line no-console
        console.warn('总共有数据', page, result)
        let articles = {
          data: this.articles.data.concat(result.data),
          to: this.articles.to + result.data.length,
          current_page: page,
          total
        }
        // eslint-disable-next-line no-console
        console.log('数据加载完毕', articles)
        this.$set(this, 'articles', articles)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.articles = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
      }
      this.loading = false
      this.initializing = false
    },
    onSearch() {
      let query = this.$clone(this.$route.query)
      query.keyword = this.keyword
      this.articles = {
        current_page: 0,
        total: 0,
        to: 0,
        data: []
      }
      this.$router.replace({ query })
      this.onRefresh()
    },
    goLink(item) {
      if(item.authorizationRequired) {
        let authenticated = ['已认证'].includes(this.userInfo?.status)
        if (!authenticated) {
          this.$dialog
            .confirm({
              title: '此内容仅向实名业主开放',
              message: '您尚未进行认证，是否立即进行业主认证？',
              theme: 'round-button',
              showCancelButton: true,
              cancelButtonText: '暂时不了',
              confirmButtonText: '业主认证'
            })
            .then(() => {
              this.$router.push({
                name: 'public.authentication'
              })
            })
            .catch(() => {})
          return
        }
      }
      this.$router.push({
        name: 'public.cms.get',
        params: { id: item._id, collection: this.collection }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.article-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .van-cell__value {
    display: none;
  }
}
</style>
