import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('Common/Cloud', ['tcb'])
  },
  methods: {
    getShareUri() {
      return location.href.indexOf('#') > 0
        ? location.href.split('#')[0]
        : location.href
    },
    async prepareWx() {
      const url = this.getShareUri()
      try {
        const instance = this.tcb

        // eslint-disable-next-line no-console
        // console.log(`url: ${url}`)
        const res = await instance.getJSSDKSignature({
          url: url
        })
        // eslint-disable-next-line no-console
        // console.log(`jssdk sign res: ${JSON.stringify(res)}`)

        const configOpt = {
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: process.env.VUE_APP_OFFICIAL_ACCOUNT_APPID, // 必填，公众号的唯一标识
          timestamp: res.timestamp + '', // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          jsApiList: [
            'closeWindow',
            'hideOptionMenu',
            'hideMenuItems',
            'hideAllNonBaseMenuItem',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'chooseImage',
            'scanQRCode'
          ], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-weapp']
        }

        // eslint-disable-next-line no-console
        // console.log(`wx.config opt ${JSON.stringify(configOpt)}`)
        wx.config(configOpt)
        // eslint-disable-next-line no-console
        // console.log('wx.config executed')

        wx.error(err => {
          // eslint-disable-next-line no-console
          console.error(`wx.error ${JSON.stringify(err)}`)
        })
        return new Promise(resolve => {
          wx.ready(() => {
            // eslint-disable-next-line no-console
            // console.log('wx.ready triggered')
            resolve()
            // wx.hideMenuItems()
            // wx.hideAllNonBaseMenuItem()
          })
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`error: ${e} ${e.stack}`)
      }
    },
    async disableShareMenu() {
      await this.prepareWx()
      wx.hideMenuItems({
        menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline']
      })
      wx.hideAllNonBaseMenuItem()
    },
    async updateShareData(options) {
      let link = location.href
      if(location.href.indexOf('#') > 0) {
        let target = location.href.split('#')[1]
        link = `${process.env.VUE_APP_BASE_URL}redirect.html?_url=${encodeURIComponent(target)}`
      }
      let shareSettings = {
        title: '阳光丽景业委会',
        desc: '阳光丽景业委会',
        link,
        imgUrl: process.env.VUE_APP_SHARE_IMAGE,
        success: function() {
          // eslint-disable-next-line no-console
          // console.log('设置分享成功')
        }
      }
      // eslint-disable-next-line no-console
      // console.log('开始设置分享', shareSettings)
      options = Object.assign(shareSettings, options)
      if(!options.imgUrl) {
        options.imgUrl = process.env.VUE_APP_SHARE_IMAGE
      }
      if(!options.desc)
      {
        options.desc = '阳光丽景业委会公众号'
      }
      wx.updateAppMessageShareData(options)
      wx.updateTimelineShareData(options)
      // eslint-disable-next-line no-console
      // console.log('完成分享设置', options)
    }
  }
}
